<template>
  <v-container fluid>
    <headers></headers>
    <v-row class="pt-5 pb-5">
      <v-divider></v-divider>
    </v-row>
    <steps></steps>
  </v-container>
</template>

<script>
const headers = () => import("../components/Headers");
const steps = () => import("../components/Steps");

export default {
  components: {
    headers,
    steps
  },
  data() {
    return {
      step: 1
    };
  }
};
</script>
